// Inline links
//
// A list of links that are inline with each other.
//
// Markup: inline-links.hbs
//
// Style guide: components.inline-links

.inline-links,
%inline-links {
  padding: 0;
  background: color(grey-extra-light);
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;

  &__item {
    display: inline;
    list-style-type: none;
    padding: 0;

    @include rtl() {
      // Bug in Safari causes display: inline to fail.
      display: inline-block;
      padding: 0;
    }
  }

  a {
    text-transform: lowercase;
    display: inline-block;
    text-decoration: none;
    background-clip: padding-box;
    margin: $spacing-tiny;
    vertical-align: middle;
    border-radius: 11px;
    background: color(grey-light) url('/sites/all/themes/insready2016/images/button.png') repeat-x;
    border: 1px solid;
    border-color: color(grey-light) color('grey') color(grey-dark);
    cursor: pointer;
    color: color(grey-dark);
    outline: 0;
    overflow: visible;
    padding: 4px 10px 3px;
    text-shadow: color('white') 0 1px 1px;
    width: auto;
  }

  a:hover {
    color: color(android-green);
  }
}

//
// Drupal selectors.
//

.inline.links {
  @extend %inline-links;

  li {
    @extend %inline-links__item;
  }
}
