// @file
// The major stylesheet for this theme.
//
// We categorize our components by creating headings in this file. See the
// description on the style guide home page for more information.


// Colors and Sass
//
// Documentation for colors and Sass mixins and variables.
//
// Weight: -1
//
// Style guide: sass
@import 'init';

// Defaults
//
// These are the default base styles applied to HTML elements.
//
// Component classes can override these styles, but if no class applies a style
// to an HTML element, these styles will be the ones displayed.
//
// Style guide: base

// Ensure fonts get loaded first to minimize front-end performance impact.
@import 'base/fonts';
@import 'base/normalize';

// Layouts
//
// The layout styling for major parts of the page that are included with the
// theme. Note: some Panels layouts are included in other parts of the system
// and are not documented.
//
// Style guide: layouts
@import 'layouts/layout-3col/layout-3col';
@import 'layouts/layout-center/layout-center';
@import 'layouts/layout-swap/layout-swap';

// Forms
//
// Form components are specialized design components that are applied to forms
// or form elements.
//
// Weight: 1
//
// Style guide: forms

// Components
//
// Design components are reusable designs that can be applied using just the CSS
// class names specified in the component.
//
// Weight: 2
//
// Style guide: components

// Navigation
//
// Style guide: components.navigation

@import 'components/autocomplete/autocomplete';
@import 'components/box/box';
@import 'components/breadcrumb/breadcrumb';
@import 'components/button/button';
@import 'components/clearfix/clearfix';
@import 'components/collapsible-fieldset/collapsible-fieldset';
@import 'components/comment/comment';
@import 'components/divider/divider';
@import 'components/form-item/form-item';
@import 'components/form-table/form-table';
@import 'components/header/header';
@import 'components/hidden/hidden';
@import 'components/highlight-mark/highlight-mark';
@import 'components/inline-links/inline-links';
@import 'components/inline-sibling/inline-sibling';
@import 'components/messages/messages';
@import 'components/more-link/more-link';
@import 'components/nav-menu/nav-menu';
@import 'components/navbar/navbar';
@import 'components/pager/pager';
@import 'components/print-none/print-none';
@import 'components/progress-bar/progress-bar';
@import 'components/progress-throbber/progress-throbber';
@import 'components/resizable-textarea/resizable-textarea';
@import 'components/responsive-video/responsive-video';
@import 'components/skip-link/skip-link';
@import 'components/table-drag/table-drag';
@import 'components/tabs/tabs';
@import 'components/visually-hidden/visually-hidden';
@import 'components/watermark/watermark';
@import 'components/wireframe/wireframe';

body {
  background: color('white') url('/sites/all/themes/insready2016/images/bg-sites.png') repeat top left;
}

.front .layout-center,
.front .layout-swap,
.layout-3col__full {
  padding: 0;
  margin: 0;
  max-width: 100%;
}

.block-search {
  float: right;

  .form-item {
    margin: 0;
  }

  .form-submit {
    display: none;
  }

  .form-text {
    color: color(grey-dark);
    display: block;
    height: 30px;
    line-height: 20px;
    background: color('white') url('/sites/all/themes/insready2016/images/searchicon.png') no-repeat scroll $spacing-tiny $spacing-tiny;
    padding: $spacing-tiny;
    border: 1px solid color(accent-color);
    border-radius: $spacing-tiny;
    padding-left: 25px;
  }
}

.block-locale {
  float: right;

  ul {
    padding-left: 0;
    margin-top: $spacing-normal;
  }

  li {
    margin-right: $spacing-large;
    padding-left: $spacing-large;
    list-style: none;
    float: left;
  }

  .zh-hans {
    background: url('/sites/all/themes/insready2016/images/zh-hans.png') no-repeat 0 6px;
  }

  .en {
    background: url('/sites/all/themes/insready2016/images/en1.png') no-repeat 0 6px;
  }
}

.particles-js {
  height: 700px;
  background: url('/sites/all/themes/insready2016/images/triangle.svg') 50% 100% no-repeat, url('/sites/all/themes/insready2016/images/insready.com_hero_image_desktop.jpg') 50% 100% no-repeat, color(drupal-blue);
}

// INsReady Intro
.pane-block-10 {
  max-width: 40%;
  position: absolute;
  left: 30%;
  top: 10%;
  z-index: 100;
  color: color('white');
  text-align: center;
}

// We are Experts in
.pane-block-14 {
  background: color('white');

  h2 {
    text-align: center;
    margin-top: 0;
    padding-top: $spacing-large;
  }

  ul {
    margin: 0 auto;
    max-width: 1140px + $zen-gutters;
    text-align: center;
    padding-left: 0;

    li {
      display: inline-block;
      width: 125px;
      margin-right: $spacing-large;
      text-align: center;
    }

    .tech-name {
      clear: both;
    }
  }
}

// Our clients
.pane-our-clients-panel-pane-1 {
  h2 {
    text-align: center;
    margin-bottom: 100px;
  }

  .views-row {
    display: inline-block;
    width: 33%;
    position: relative;
    margin-bottom: 100px;
    text-align: center;
  }

  .views-field-field-client-logo {
    position: absolute;
    z-index: 10;
    background: color('white');
    left: 40%;
    border-radius: 50%;
    top: -50px;
    height: 100px;
    box-shadow: 0 0 $spacing-tiny 1px color(grey-light);

    img {
      border-radius: 50%;
    }
  }

  .views-field-field-image img {
    -webkit-filter: grayscale(1);
    -webkit-filter: grayscale(100%);
    filter: grayscale(100%);
    filter: color('grey');
    transition: 1s all;
  }

  .views-field-field-image img:hover {
    -webkit-filter: none;
    filter: none;
  }
}

// Recent Blogs
.pane-blocks {
  h2 {
    text-align: center;
  }

  .view-blocks {
    max-width: 1140px + $zen-gutters;
    margin: 0 auto;
  }

  .view-content {
    height: 200px;
  }

  .views-row {
    width: 100%;
    max-width: 550px;
    display: inline-block;
    float: left;
    margin-bottom: $spacing-normal;

    &:first-child {
      margin-right: 50px;
    }
  }

  .views-field-title,
  .views-field-body {
    background-color: color(grey-extra-light);
    padding: $spacing-normal;
    margin-left: 47px;
  }

  .views-field-title {
    @include font-size(l);
  }

  .views-field-body {
    height: 110px;

    p {
      margin-top: 0;
    }
  }

  .views-field-picture {
    float: right;
    margin: $spacing-tiny;
  }

  .views-field-created-1 {
    border-radius: 3px;
    background-color: color('white');
    border: 1px solid color(grey-extra-light);
    border-bottom: 1px solid color(grey-light);
    float: left;
    padding: $spacing-normal;
    margin: $spacing-normal;

    .month {
      display: block;
      line-height: 22px;
      text-align: center;
      color: color(drupal-blue);
      border-bottom: 1px solid color(grey-extra-light);
    }
  }

  .more-link {
    width: 50px;
    margin: 0 auto;
  }
}

.region-bottom-wrapper {
  background: url('/sites/all/themes/insready2016/images/bg-footer.png') repeat-x;
  height: 180px;
  border-top: 6px solid color(grey-light);
}

.region-bottom {
  max-width: 1140px + $zen-gutters;
  margin: 0 auto;
  color: color('white');

  .block-menu {
    float: right;
    text-align: right;
    margin-top: $spacing-normal;

    h2 {
      @include font-size(m);
      display: inline-block;
      margin: 0;
    }

    ul {
      margin-top: 0;
    }

    li {
      display: inline-block;
      margin-left: $spacing-large;
    }
  }

  a:link,
  a:visited {
    color: color('white');
  }

  a:hover {
    color: color(drupal-blue);
  }

  // Copyright
  #block-block-6 {
    float: right;
    clear: right;

    p {
      margin-bottom: 0;
    }
  }
}

.user-picture {
  float: right;
  margin-left: $spacing-normal;
}

// Services page
.menu-name-main-menu {
  background-image: url('/sites/all/themes/insready2016/images/bg-navbar.png');
  background-color: color(accent-color);
  padding: $spacing-normal;
  margin-top: 100px;

  a {
    text-decoration: none;
    color: color('white');
    display: inline-block;
    font-size: 100%;
    width: 210px;
    line-height: 30px;
    padding-left: $spacing-normal;

    &.active-trail {
      color: color(android-green);
    }
  }

  a:hover {
    color: color(drupal-blue);
  }
}

// Blogs page
.page-blogs {
  ul {
    padding-left: 0;
  }

  li {
    list-style: none;
  }

  header {
    padding-left: 0;
  }
}

// Works page
.page-works {
  td {
    width: 32%;
    text-align: center;
    display: inline-block;

    span {
      display: block;
    }
  }

  tr {
    display: block;
    margin-bottom: $spacing-large;
  }
}

@media all and (min-width: 436px) and (max-width: 1024px) {
  .particles-js {
    height: 380px;
    background: url('/sites/all/themes/insready2016/images/triangle.svg') 50% 100% no-repeat, url('/sites/all/themes/insready2016/images/insready.com_hero_image_tablet.jpg') 50% 100% no-repeat, color(drupal-blue);
  }

  .pane-our-clients-panel-pane-1 .views-row {
    width: 49%;
  }

  // INsReady intro
  .pane-block-10 {
    max-width: 100%;
    top: 1%;
    left: 0;
    padding: 20px;
  }

  // Recent Blogs
  .pane-blocks .views-row {
    max-width: 500px;
  }

  .pane-blocks .views-row:first-child {
    margin-right: 0;
  }
}

@media all and (max-width: 435px) {
  .particles-js {
    height: 190px;
    background: url('/sites/all/themes/insready2016/images/triangle.svg') 50% 100% no-repeat, url('/sites/all/themes/insready2016/images/insready.com_hero_image_phone.jpg') 50% 100% no-repeat, color(drupal-blue);
  }

  .pane-our-clients-panel-pane-1 .views-row {
    width: 100%;
  }

  // INsReady intro
  .pane-block-10 {
    max-width: 100%;
    top: 0;
    left: 0;
    padding: 20px;
    background: rgba(color('black'), .2);

    h1 {
      @include font-size(m);
      line-height: 14px;
    }
  }

  .region-bottom .block-menu {
    float: none;
    margin-top: 0;
    margin-right: $spacing-normal;

    h2 {
      display: none;
    }
  }

  .region-header {
    position: absolute;
    right: 0;
    top: 0;
  }

  .block-locale ul {
    margin: $spacing-tiny;

    li {
      margin-right: $spacing-tiny;
    }
  }

  // Works page
  .page-works td {
    width: 100%;
  }
}
