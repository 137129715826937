// Collapsible fieldset
//
// Fieldsets with optional entry fields can be hidden with collapsed fieldsets.
//
// .is-collapsed - The collapsed fieldset.
//
// Markup: collapsible-fieldset.hbs
//
// Style guide: forms.collapsible-fieldset

.collapsible-fieldset,
%collapsible-fieldset {
  position: relative;

  &__legend {
    display: block;
    padding-left: 15px;
    @if support-for(ie, 8) {
      // IE8 does not support multiple backgrounds, so IE8 will ignore the second
      // declaration with the SVG. https://css-tricks.com/using-svg/
      // scss-lint:disable DuplicateProperty
      background-image: image-url('collapsible-fieldset/expanded.png');
      background-image: image-url('collapsible-fieldset/expanded.svg'), none;
    }
    @else {
      background-image: image-url('collapsible-fieldset/expanded.svg');
    }
    background-position: 4px 50%;
    background-repeat: no-repeat;

    @include rtl() {
      padding-left: 0;
      padding-right: 15px;
      @if support-for(ie, 8) {
        // IE 8 does not understand edge offsets.
        background-position: 98% 50%;
      }
      background-position: right 4px top 50%;
    }
  }

  &__summary {
    color: color(fieldset-summary);
    font-size: .9em;
    margin-left: .5em;
  }

  &.is-collapsed,
  &--is-collapsed {
    border-bottom-width: 0;
    border-left-width: 0;
    border-right-width: 0;
    height: 1em;

    &__wrapper {
      display: none;
    }

    &__legend {
      @if support-for(ie, 8) {
        // scss-lint:disable DuplicateProperty
        background-image: image-url('collapsible-fieldset/collapsed.png');
        background-image: image-url('collapsible-fieldset/collapsed.svg'), none;
      }
      @else {
        background-image: image-url('collapsible-fieldset/collapsed.svg');
      }
      background-position: 4px 50%;

      @include rtl() {
        @if support-for(ie, 8) {
          background-image: image-url('collapsible-fieldset/collapsed-rtl.png');
          background-image: image-url('collapsible-fieldset/collapsed-rtl.svg'), none;
          background-position: 98% 50%;
        }
        @else {
          background-image: image-url('collapsible-fieldset/collapsed-rtl.svg');
        }
        background-position: right 4px top 50%;
      }
    }
  }
}

//
// Drupal selectors.
//

.collapsible {
  @extend %collapsible-fieldset;

  .fieldset-legend {
    @extend %collapsible-fieldset__legend;

    .summary {
      @extend %collapsible-fieldset__summary;
    }
  }

  &.collapsed {
    @extend %collapsible-fieldset--is-collapsed;

    .fieldset-legend {
      @extend %collapsible-fieldset--is-collapsed__legend;
    }

    .fieldset-wrapper {
      @extend %collapsible-fieldset--is-collapsed__wrapper;
    }
  }
}
