// Dependencies.
@import 'components/visually-hidden/visually-hidden';

// Navbar
//
// A simple method to get navigation links to appear in one line.
//
// Markup: navbar.hbs
//
// Style guide: components.navigation.navbar

nav {
  float: left;
}

.navbar,
%navbar {
  margin: 0;
  padding: 0;
  text-align: left;

  @include rtl() {
    text-align: right;
  }

  &__item,
  li {
    float: left;
    display: inline-block;
    padding: 0;
    list-style-type: none;
    list-style-image: none;

    @include rtl() {
      float: right;
      padding: 0 0 0 10px;
    }

    a {
      text-decoration: none;
      color: color('white');
      display: inline-block;
      padding: $spacing-normal;
      text-transform: lowercase;

      &.active {
        background-image: url('/sites/all/themes/insready2016/images/bg-footer.png');
      }

      &::first-letter {
        font-size: 120%;
      }
    }

    a:hover {
      background: color(menu-bg);
    }
  }
}

//
// Drupal selectors.
//

.main-navigation {
  // Hide the block titles put into the main-navigation area of the page.tpl.php.
  .block-menu .block__title,
  .block-menu-block .block__title {
    @extend %visually-hidden;
  }

  // Main menu and secondary menu links and menu block links.
  .links,
  .menu {
    @extend %navbar;
  }
}

@media all and (max-width: 435px) {
  .navbar li a {
    padding: $spacing-small;
  }
}
