// scss-lint:disable ImportantRule

// visually-hidden()
//
// Make an element visually hidden, but accessible to screen readers, etc.
// @see http://snook.ca/archives/html_and_css/hiding-content-for-accessibility
//
// Style guide: sass.mixins.visually-hidden
@mixin visually-hidden {
  position: absolute !important;
  height: 1px;
  width: 1px;
  overflow: hidden;
  clip: rect(1px, 1px, 1px, 1px);
  word-wrap: normal;
}

// visually-hidden--off()
//
// Turns off the visually-hidden effect.
//
// Style guide: sass.mixins.visually-hidden--off
@mixin visually-hidden--off {
  position: static !important;
  clip: auto;
  height: auto;
  width: auto;
  overflow: visible;
}

// visually-hidden--focusable()
//
// Makes an element visually hidden by default, but visible when receiving
// focus.
//
// Style guide: sass.mixins.visually-hidden--focusable
@mixin visually-hidden--focusable {
  @include visually-hidden;

  &:active,
  &:focus {
    @include visually-hidden--off;
  }
}
