// Tables
//
// The `<table>` element displays tabular data in rows, columns, and cells.
//
// Markup: tables-table.hbs
//
// Weight: 1
//
// Style guide: base.table

table {
  // Add vertical rhythm margins.
  @include margin(1 0);
  // Remove most spacing between table cells.
  border-collapse: collapse;
  border-spacing: 0;
  // Prevent cramped-looking tables
  width: 100%;
}

td,
th {
  // Remove most spacing between table cells.
  padding: 0;
}
