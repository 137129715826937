@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  src: local("Roboto"), local("Roboto-Regular"), url("/sites/all/themes/insready2016/fonts/roboto-normal.woff2") format("woff2");
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2212, U+2215, U+E0FF, U+EFFD, U+F000;
}

* {
  box-sizing: border-box;
}

html {
  font-size: 100%;
  line-height: 1.5em;
  font-family: Roboto, Helvetica, Arial, "Hiragino Sans GB", "Microsoft YaHei", "WenQuanYi Micro Hei", sans-serif;
  -ms-text-size-adjust: 100%;
  -webkit-text-size-adjust: 100%;
  min-height: 100%;
}

@media print {
  html {
    font-size: 12pt;
  }
}

body {
  margin: 0;
  padding: 0;
}

article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
main,
menu,
nav,
section,
summary {
  display: block;
}

audio,
canvas,
progress,
video {
  display: inline-block;
  vertical-align: baseline;
}

audio:not([controls]) {
  display: none;
  height: 0;
}

[hidden] {
  display: none;
}

template {
  display: none;
}

:link {
  color: #0073ba;
  text-decoration: none;
}

:visited {
  color: #0073ba;
}

a:hover,
a:focus {
  color: #94bf25;
}

a:active {
  color: #c00;
  background-color: transparent;
}

a:active,
a:hover {
  outline: 0;
}

@media print {
  :link,
  :visited {
    text-decoration: underline;
  }
  a[href]:after {
    content: " (" attr(href) ")";
    font-weight: normal;
    font-size: 16px;
    text-decoration: none;
  }
  a[href^='javascript:']:after,
  a[href^='#']:after {
    content: '';
  }
}

h1 {
  font-size: 32px;
  line-height: 48px;
  font-size: 2rem;
  line-height: 3rem;
  margin-top: 24px;
  margin-top: 1.5rem;
  margin-bottom: 24px;
  margin-bottom: 1.5rem;
}

h2 {
  font-size: 24px;
  line-height: 36px;
  font-size: 1.5rem;
  line-height: 2.25rem;
  margin-top: 24px;
  margin-top: 1.5rem;
  margin-bottom: 24px;
  margin-bottom: 1.5rem;
}

h3 {
  font-size: 20px;
  line-height: 36px;
  font-size: 1.25rem;
  line-height: 2.25rem;
  margin-top: 24px;
  margin-top: 1.5rem;
  margin-bottom: 24px;
  margin-bottom: 1.5rem;
}

h4 {
  font-size: 16px;
  line-height: 24px;
  font-size: 1rem;
  line-height: 1.5rem;
  margin-top: 24px;
  margin-top: 1.5rem;
  margin-bottom: 24px;
  margin-bottom: 1.5rem;
}

h5 {
  font-size: 14px;
  line-height: 24px;
  font-size: 0.875rem;
  line-height: 1.5rem;
  margin-top: 24px;
  margin-top: 1.5rem;
  margin-bottom: 24px;
  margin-bottom: 1.5rem;
}

h6 {
  font-size: 12px;
  line-height: 24px;
  font-size: 0.75rem;
  line-height: 1.5rem;
  margin-top: 24px;
  margin-top: 1.5rem;
  margin-bottom: 24px;
  margin-bottom: 1.5rem;
}

abbr[title] {
  border-bottom: 1px dotted;
}

@media print {
  abbr[title]:after {
    content: " (" attr(title) ")";
  }
}

b,
strong {
  font-weight: bold;
}

dfn {
  font-style: italic;
}

mark {
  background: #fd0;
  color: #000;
}

small {
  font-size: 14px;
  font-size: 0.875rem;
}

sub,
sup {
  font-size: 12px;
  font-size: 0.75rem;
  line-height: 0;
  position: relative;
  vertical-align: baseline;
}

sup {
  top: -.5em;
}

sub {
  bottom: -.25em;
}

.divider,
hr {
  margin: 24px 0px;
  margin: 1.5rem 0rem;
  border: 0;
  border-top: 1px solid #ddd;
}

.divider > :first-child, hr > :first-child {
  margin-top: 24px;
  margin-top: 1.5rem;
}

dl,
menu,
ol,
ul {
  margin: 24px 0px;
  margin: 1.5rem 0rem;
}

ol ol,
ol ul,
ul ol,
ul ul {
  margin: 0;
}

dd {
  margin: 0 0 0 32px;
}

[dir="rtl"] dd {
  margin: 0 32px 0 0;
}

menu,
ol,
ul {
  padding: 0 0 0 32px;
}

[dir="rtl"] menu, [dir="rtl"]
ol, [dir="rtl"]
ul {
  padding: 0 32px 0 0;
}

blockquote {
  margin: 24px 32px;
  margin: 1.5rem 2rem;
}

figure {
  margin: 24px 0px;
  margin: 1.5rem 0rem;
}

hr {
  box-sizing: content-box;
  height: 0;
}

p,
pre {
  margin: 24px 0px;
  margin: 1.5rem 0rem;
}

code,
kbd,
samp,
var, pre {
  font-family: Menlo, "DejaVu Sans Mono", "Ubuntu Mono", Courier, "Courier New", monospace, sans-serif;
  font-size: 1em;
}

pre {
  overflow: auto;
}

img {
  border: 0;
}

img,
svg {
  max-width: 100%;
  height: auto;
}

.lt-ie9 img, .lt-ie9
svg {
  width: auto;
}

svg:not(:root) {
  overflow: hidden;
}

.button,
button,
[type="button"],
[type="reset"],
[type="submit"] {
  -moz-appearance: button;
  -webkit-appearance: button;
  display: inline-block;
  padding: 2px 6px;
  line-height: inherit;
  text-decoration: none;
  cursor: pointer;
  color: #000;
}

.button:hover, .button:focus, .button:active,
button:hover,
[type="button"]:hover,
[type="reset"]:hover,
[type="submit"]:hover,
button:focus,
[type="button"]:focus,
[type="reset"]:focus,
[type="submit"]:focus,
button:active,
[type="button"]:active,
[type="reset"]:active,
[type="submit"]:active {
  text-decoration: none;
  color: #000;
}

.button[disabled],
button[disabled],
[disabled][type="button"],
[disabled][type="reset"],
[disabled][type="submit"] {
  cursor: default;
  color: #999999;
}

.button[disabled]:hover, .button[disabled]:focus, .button[disabled]:active,
button[disabled]:hover,
[disabled][type="button"]:hover,
[disabled][type="reset"]:hover,
[disabled][type="submit"]:hover,
button[disabled]:focus,
[disabled][type="button"]:focus,
[disabled][type="reset"]:focus,
[disabled][type="submit"]:focus,
button[disabled]:active,
[disabled][type="button"]:active,
[disabled][type="reset"]:active,
[disabled][type="submit"]:active {
  color: #999999;
}

button,
input,
optgroup,
select,
textarea {
  box-sizing: border-box;
  max-width: 100%;
  color: inherit;
  font: inherit;
  margin: 0;
}

button {
  overflow: visible;
}

button,
select {
  text-transform: none;
}

button::-moz-focus-inner,
input::-moz-focus-inner {
  border: 0;
  padding: 0;
}

html input[type="button"],
[type="reset"],
[type="submit"] {
  -webkit-appearance: button;
}

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  height: auto;
}

input[type="search"] {
  -webkit-appearance: textfield;
}

input[type="search"]::-webkit-search-cancel-button, input[type="search"]::-webkit-search-decoration {
  -webkit-appearance: none;
}

input[type="text"], input[type="search"], input[type="tel"], input[type="url"], input[type="email"], input[type="password"], input[type="date"], input[type="time"], input[type="number"], textarea {
  line-height: inherit;
}

input[type="checkbox"],
input[type="radio"] {
  box-sizing: border-box;
  padding: 0;
}

fieldset {
  padding: 8.4px 15px 15.6px;
  padding: 0.525rem 0.9375rem 0.975rem;
  border: 1px solid #ddd;
  margin: 0 2px;
}

legend {
  border: 0;
  margin-left: -5px;
  padding: 0 5px;
}

label {
  display: block;
  font-weight: bold;
}

optgroup {
  font-weight: bold;
}

textarea {
  overflow: auto;
}

table {
  margin: 24px 0px;
  margin: 1.5rem 0rem;
  border-collapse: collapse;
  border-spacing: 0;
  width: 100%;
}

td,
th {
  padding: 0;
}

.layout-3col {
  margin-left: -20px;
  margin-right: -20px;
  padding-left: 0;
  padding-right: 0;
}

.layout-3col:before {
  content: "";
  display: table;
}

.layout-3col:after {
  content: "";
  display: table;
  clear: both;
}

.layout-3col__full, .layout-3col__left-content, .layout-3col__right-content, .layout-3col__left-sidebar, .layout-3col__right-sidebar, .layout-3col__first-left-sidebar, .layout-3col__second-left-sidebar, .layout-3col__col-1, .layout-3col__col-2, .layout-3col__col-3, .layout-3col__col-4, .layout-3col__col-x {
  clear: both;
  padding-left: 20px;
  padding-right: 20px;
  float: left;
  width: 100%;
  margin-left: 0%;
  margin-right: -100%;
}

[dir="rtl"] .layout-3col__full, [dir="rtl"] .layout-3col__left-content, [dir="rtl"] .layout-3col__right-content, [dir="rtl"] .layout-3col__left-sidebar, [dir="rtl"] .layout-3col__right-sidebar, [dir="rtl"] .layout-3col__first-left-sidebar, [dir="rtl"] .layout-3col__second-left-sidebar, [dir="rtl"] .layout-3col__col-1, [dir="rtl"] .layout-3col__col-2, [dir="rtl"] .layout-3col__col-3, [dir="rtl"] .layout-3col__col-4, [dir="rtl"] .layout-3col__col-x {
  margin-right: 0%;
  margin-left: -100%;
}

@media (min-width: 777px) {
  .layout-3col {
    margin-left: -12px;
    margin-right: -13px;
    padding-left: 0;
    padding-right: 0;
  }
  .layout-3col:before {
    content: "";
    display: table;
  }
  .layout-3col:after {
    content: "";
    display: table;
    clear: both;
  }
  [dir="rtl"] .layout-3col {
    margin-left: -13px;
    margin-right: -12px;
  }
  .layout-3col__full, .layout-3col__left-content, .layout-3col__right-content, .layout-3col__left-sidebar, .layout-3col__right-sidebar {
    float: left;
    width: 100%;
    margin-left: 0%;
    margin-right: -100%;
    padding-left: 12px;
    padding-right: 13px;
  }
  [dir="rtl"] .layout-3col__full, [dir="rtl"] .layout-3col__left-content, [dir="rtl"] .layout-3col__right-content, [dir="rtl"] .layout-3col__left-sidebar, [dir="rtl"] .layout-3col__right-sidebar {
    margin-right: 0%;
    margin-left: -100%;
  }
  [dir="rtl"] .layout-3col__full, [dir="rtl"] .layout-3col__left-content, [dir="rtl"] .layout-3col__right-content, [dir="rtl"] .layout-3col__left-sidebar, [dir="rtl"] .layout-3col__right-sidebar {
    padding-left: 13px;
    padding-right: 12px;
  }
  .layout-3col__first-left-sidebar, .layout-3col__col-1, .layout-3col__col-3, .layout-3col__col-x:nth-child(2n + 1) {
    float: left;
    width: 50%;
    margin-left: 0%;
    margin-right: -100%;
    padding-left: 12px;
    padding-right: 13px;
  }
  [dir="rtl"] .layout-3col__first-left-sidebar, [dir="rtl"] .layout-3col__col-1, [dir="rtl"] .layout-3col__col-3, [dir="rtl"] .layout-3col__col-x:nth-child(2n + 1) {
    margin-right: 0%;
    margin-left: -100%;
  }
  [dir="rtl"] .layout-3col__first-left-sidebar, [dir="rtl"] .layout-3col__col-1, [dir="rtl"] .layout-3col__col-3, [dir="rtl"] .layout-3col__col-x:nth-child(2n + 1) {
    padding-left: 13px;
    padding-right: 12px;
  }
  .layout-3col__second-left-sidebar, .layout-3col__col-2, .layout-3col__col-4, .layout-3col__col-x:nth-child(2n) {
    clear: none;
    float: left;
    width: 50%;
    margin-left: 50%;
    margin-right: -100%;
    padding-left: 12px;
    padding-right: 13px;
  }
  [dir="rtl"] .layout-3col__second-left-sidebar, [dir="rtl"] .layout-3col__col-2, [dir="rtl"] .layout-3col__col-4, [dir="rtl"] .layout-3col__col-x:nth-child(2n) {
    margin-right: 50%;
    margin-left: -100%;
  }
  [dir="rtl"] .layout-3col__second-left-sidebar, [dir="rtl"] .layout-3col__col-2, [dir="rtl"] .layout-3col__col-4, [dir="rtl"] .layout-3col__col-x:nth-child(2n) {
    padding-left: 13px;
    padding-right: 12px;
  }
}

@media (min-width: 999px) {
  .layout-3col__full {
    float: left;
    width: 100%;
    margin-left: 0%;
    margin-right: -100%;
  }
  [dir="rtl"] .layout-3col__full {
    margin-right: 0%;
    margin-left: -100%;
  }
  .layout-3col__left-content {
    float: left;
    width: 66.66667%;
    margin-left: 0%;
    margin-right: -100%;
  }
  [dir="rtl"] .layout-3col__left-content {
    margin-right: 0%;
    margin-left: -100%;
  }
  .layout-3col__right-content {
    float: left;
    width: 66.66667%;
    margin-left: 33.33333%;
    margin-right: -100%;
  }
  [dir="rtl"] .layout-3col__right-content {
    margin-right: 33.33333%;
    margin-left: -100%;
  }
  .layout-3col__left-sidebar, .layout-3col__first-left-sidebar, .layout-3col__second-left-sidebar {
    clear: right;
    float: right;
    width: 33.33333%;
    margin-right: 66.66667%;
    margin-left: -100%;
  }
  [dir="rtl"] .layout-3col__left-sidebar, [dir="rtl"] .layout-3col__first-left-sidebar, [dir="rtl"] .layout-3col__second-left-sidebar {
    clear: left;
  }
  [dir="rtl"] .layout-3col__left-sidebar, [dir="rtl"] .layout-3col__first-left-sidebar, [dir="rtl"] .layout-3col__second-left-sidebar {
    margin-left: 66.66667%;
    margin-right: -100%;
  }
  .layout-3col__right-sidebar {
    clear: right;
    float: right;
    width: 33.33333%;
    margin-right: 0%;
    margin-left: -100%;
  }
  [dir="rtl"] .layout-3col__right-sidebar {
    clear: left;
  }
  [dir="rtl"] .layout-3col__right-sidebar {
    margin-left: 0%;
    margin-right: -100%;
  }
  .layout-3col__col-1, .layout-3col__col-x:nth-child(3n+1) {
    clear: both;
    float: left;
    width: 33.33333%;
    margin-left: 0%;
    margin-right: -100%;
  }
  [dir="rtl"] .layout-3col__col-1, [dir="rtl"] .layout-3col__col-x:nth-child(3n+1) {
    margin-right: 0%;
    margin-left: -100%;
  }
  .layout-3col__col-2, .layout-3col__col-x:nth-child(3n+2) {
    clear: none;
    float: left;
    width: 33.33333%;
    margin-left: 33.33333%;
    margin-right: -100%;
  }
  [dir="rtl"] .layout-3col__col-2, [dir="rtl"] .layout-3col__col-x:nth-child(3n+2) {
    margin-right: 33.33333%;
    margin-left: -100%;
  }
  .layout-3col__col-3, .layout-3col__col-x:nth-child(3n) {
    clear: none;
    float: left;
    width: 33.33333%;
    margin-left: 66.66667%;
    margin-right: -100%;
  }
  [dir="rtl"] .layout-3col__col-3, [dir="rtl"] .layout-3col__col-x:nth-child(3n) {
    margin-right: 66.66667%;
    margin-left: -100%;
  }
  .layout-3col__col-4 {
    display: none;
  }
}

.layout-3col__grid-item-container {
  padding-left: 0;
  padding-right: 0;
}

.layout-3col__grid-item-container:before {
  content: "";
  display: table;
}

.layout-3col__grid-item-container:after {
  content: "";
  display: table;
  clear: both;
}

.layout-center,
header {
  padding-left: 20px;
  padding-right: 20px;
  position: relative;
  margin: 0 auto;
  max-width: 1165px;
}

@media (min-width: 777px) {
  .layout-center,
  header {
    padding-left: 12px;
    padding-right: 13px;
  }
  [dir="rtl"] .layout-center, [dir="rtl"]
  header {
    padding-left: 13px;
    padding-right: 12px;
  }
}

.layout-center--shared-grid,
.layout-center.layout-3col,
header--shared-grid {
  padding-left: 0;
  padding-right: 0;
}

.layout-swap {
  position: relative;
}

@media (min-width: 555px) {
  .layout-swap__top {
    position: absolute;
    top: 0;
    height: 48px;
    width: 100%;
  }
}

.autocomplete,
.form-autocomplete {
  background-image: url(../sass/components/autocomplete/throbber-inactive.png);
  background-position: 100% center;
  background-repeat: no-repeat;
}

[dir="rtl"] .autocomplete,
[dir="rtl"] .form-autocomplete {
  background-position: 0% center;
}

.autocomplete__list-wrapper,
#autocomplete {
  border: 1px solid;
  overflow: hidden;
  position: absolute;
  z-index: 100;
}

.autocomplete__list,
#autocomplete ul {
  list-style: none;
  list-style-image: none;
  margin: 0;
  padding: 0;
}

.autocomplete__list-item,
#autocomplete li {
  background: #fff;
  color: #000;
  cursor: default;
  white-space: pre;
}

.autocomplete__list-item--is-selected,
#autocomplete .selected {
  background: #0072b9;
  color: #fff;
}

.autocomplete.is-throbbing, .autocomplete--is-throbbing,
.is-throbbing.form-autocomplete,
.form-autocomplete.throbbing {
  background-image: url(../sass/components/autocomplete/throbber-active.gif);
}

.box {
  margin-bottom: 24px;
  margin-bottom: 1.5rem;
  border: 5px solid #ddd;
  padding: 1em;
}

.box__title {
  margin: 0;
}

.box:focus, .box:hover, .box.is-focus, .box--is-focus {
  border-color: #000;
}

.box--highlight {
  border-color: #0072b9;
}

.visually-hidden,
.visually-hidden--focusable,
.element-focusable,
.element-invisible,
.breadcrumb__title,
.main-navigation .block-menu .block__title,
.main-navigation .block-menu-block .block__title {
  position: absolute !important;
  height: 1px;
  width: 1px;
  overflow: hidden;
  clip: rect(1px, 1px, 1px, 1px);
  word-wrap: normal;
}

.visually-hidden--off,
.visually-hidden--focusable:active,
.visually-hidden--focusable:focus,
.element-focusable:active,
.element-focusable:focus {
  position: static !important;
  clip: auto;
  height: auto;
  width: auto;
  overflow: visible;
}

@media print {
  .breadcrumb {
    display: none;
  }
}

.breadcrumb__list {
  margin: 0;
  padding: 0;
}

.breadcrumb__item {
  display: inline;
  list-style-type: none;
  margin: 0;
  padding: 0;
}

.clearfix:before,
.header:before,
.tabs:before,
.tabs--secondary:before {
  content: '';
  display: table;
}

.clearfix:after,
.header:after,
.tabs:after,
.tabs--secondary:after {
  content: '';
  display: table;
  clear: both;
}

.collapsible-fieldset,
.collapsible {
  position: relative;
}

.collapsible-fieldset__legend,
.collapsible .fieldset-legend {
  display: block;
  padding-left: 15px;
  background-image: url(../sass/components/collapsible-fieldset/expanded.png);
  background-image: url(../sass/components/collapsible-fieldset/expanded.svg), none;
  background-position: 4px 50%;
  background-repeat: no-repeat;
}

[dir="rtl"] .collapsible-fieldset__legend,
[dir="rtl"] .collapsible .fieldset-legend,
.collapsible [dir="rtl"] .fieldset-legend {
  padding-left: 0;
  padding-right: 15px;
  background-position: 98% 50%;
  background-position: right 4px top 50%;
}

.collapsible-fieldset__summary,
.collapsible .fieldset-legend .summary {
  color: #999999;
  font-size: .9em;
  margin-left: .5em;
}

.collapsible-fieldset.is-collapsed, .collapsible-fieldset--is-collapsed,
.is-collapsed.collapsible,
.collapsible.collapsed {
  border-bottom-width: 0;
  border-left-width: 0;
  border-right-width: 0;
  height: 1em;
}

.collapsible-fieldset.is-collapsed__wrapper, .collapsible-fieldset--is-collapsed__wrapper,
.is-collapsed__wrapper.collapsible,
.collapsible.collapsed .fieldset-wrapper {
  display: none;
}

.collapsible-fieldset.is-collapsed__legend, .collapsible-fieldset--is-collapsed__legend,
.is-collapsed__legend.collapsible,
.collapsible.collapsed .fieldset-legend {
  background-image: url(../sass/components/collapsible-fieldset/collapsed.png);
  background-image: url(../sass/components/collapsible-fieldset/collapsed.svg), none;
  background-position: 4px 50%;
}

[dir="rtl"] .collapsible-fieldset.is-collapsed__legend, [dir="rtl"] .collapsible-fieldset--is-collapsed__legend,
[dir="rtl"] .is-collapsed__legend.collapsible,
[dir="rtl"] .collapsible.collapsed .fieldset-legend,
.collapsible.collapsed [dir="rtl"] .fieldset-legend {
  background-image: url(../sass/components/collapsible-fieldset/collapsed-rtl.png);
  background-image: url(../sass/components/collapsible-fieldset/collapsed-rtl.svg), none;
  background-position: 98% 50%;
  background-position: right 4px top 50%;
}

.comment__section,
.comments {
  margin: 24px 0px;
  margin: 1.5rem 0rem;
}

.comment__title {
  margin: 0;
}

.comment__permalink {
  text-transform: uppercase;
  font-size: 75%;
}

.comment--preview,
.comment-preview {
  background-color: #fffadb;
}

.comment--nested,
.indented {
  margin-left: 32px;
}

[dir="rtl"] .comment--nested,
[dir="rtl"] .indented {
  margin-left: 0;
  margin-right: 32px;
}

.form-item {
  margin: 24px 0px;
  margin: 1.5rem 0rem;
}

.form-item__required,
.form-required {
  color: #c00;
}

.form-item__description,
.form-item .description {
  font-size: 14px;
  font-size: 0.875rem;
}

.form-item--inline div,
.form-item--inline label, .container-inline .form-item div, .container-inline .form-item label {
  display: inline;
}

.form-item--inline__exception,
.container-inline .fieldset-wrapper {
  display: block;
}

.form-item--tight,
.form-item--radio,
.form-type-radio,
.form-type-checkbox,
.password-parent,
.confirm-parent,
table .form-item {
  margin: 0;
}

.form-item--radio__label,
.form-item--radio .form-item__label,
label.option, .form-type-radio .form-item__label,
.form-type-checkbox .form-item__label {
  display: inline;
  font-weight: normal;
}

.form-item--radio__description,
.form-item--radio .form-item__description,
.form-type-radio .description,
.form-type-checkbox .description, .form-type-radio .form-item__description,
.form-type-checkbox .form-item__description,
.form-item--radio .form-item .description,
.form-item
.form-item--radio .description,
.form-type-radio .form-item .description,
.form-item .form-type-radio .description,
.form-type-checkbox .form-item .description,
.form-item
.form-type-checkbox .description {
  margin-left: 1.4em;
}

.form-item.is-error .form-item__widget, .form-item--is-error__widget,
.form-item.is-error input,
.form-item.is-error textarea,
.form-item.is-error select,
.form-item--is-error input,
.form-item--is-error textarea,
.form-item--is-error select,
.form-item input.error,
.form-item textarea.error,
.form-item select.error {
  border: 1px solid #c00;
}

.form-table__sticky-header,
.sticky-header {
  position: fixed;
  visibility: hidden;
  margin-top: 0;
  background-color: #fff;
}

.form-table__sticky-header.is-sticky, .form-table__sticky-header--is-sticky,
.is-sticky.sticky-header {
  visibility: visible;
}

.form-table__header,
.form-table th,
form table th {
  border-bottom: 3px solid #ddd;
  padding-right: 1em;
  text-align: left;
}

[dir="rtl"] .form-table__header,
[dir="rtl"] .form-table th,
.form-table [dir="rtl"] th,
[dir="rtl"] form table th,
form table [dir="rtl"] th {
  text-align: right;
  padding-left: 1em;
  padding-right: 0;
}

.form-table__body,
.form-table tbody,
form table tbody {
  border-top: 1px solid #ddd;
}

.form-table__row,
.form-table tbody tr,
form table tbody tr {
  padding: .1em .6em;
  border-bottom: 1px solid #ddd;
  background-color: #eeeeee;
}

.form-table__row:nth-child(even),
.form-table tbody tr:nth-child(even),
form table tbody tr:nth-child(even) {
  background-color: #fff;
}

.form-table__row.is-active, .form-table__row--is-active,
.form-table tbody tr.is-active,
form table tbody tr.is-active,
td.active {
  background-color: #dddddd;
}

.form-table__row.is-disabled, .form-table__row--is-disabled,
.form-table tbody tr.is-disabled,
form table tbody tr.is-disabled,
td.menu-disabled {
  background: #ddd;
}

.form-table__row.is-selected, .form-table__row--is-selected,
.form-table tbody tr.is-selected,
form table tbody tr.is-selected,
tr.selected td {
  background: #fffdf0;
}

.form-table__list,
.form-table ul,
form table ul {
  margin: 0;
}

.form-table__narrow-column,
.form-table th.form-table__narrow-column,
form table th.form-table__narrow-column,
td .checkbox,
th .checkbox {
  width: -moz-min-content;
  width: -webkit-min-content;
  text-align: center;
}

.header-wrapper {
  background-image: url("/sites/all/themes/insready2016/images/bg-navbar.png");
  margin: 0;
  background-color: #3d3d3d;
  border-bottom: 2px;
  border-bottom-color: #a40000;
  border-bottom-style: solid;
}

.header__logo {
  float: left;
  margin: 7px 10px 0 0;
  padding: 0;
}

[dir="rtl"] .header__logo {
  float: right;
  margin: 0 0 0 10px;
}

.header__logo-image {
  vertical-align: bottom;
}

.header__name-and-slogan {
  font-size: 14px;
  font-size: 0.875rem;
  float: left;
  color: #fff;
}

.header__site-name {
  font-size: 16px;
  font-size: 1rem;
  line-height: 1em;
  margin: 10px 150px 0 0;
}

.header__site-name span {
  color: #fff;
}

.header__site-link:link, .header__site-link:visited {
  color: #000;
  text-decoration: none;
}

.header__site-link:hover, .header__site-link:focus {
  text-decoration: underline;
}

.header__site-slogan {
  margin: 0;
  font-style: italic;
}

.header__secondary-menu {
  float: right;
}

[dir="rtl"] .header__secondary-menu {
  float: left;
}

.header__region {
  position: relative;
}

.hidden,
html.js .js-hidden,
html.js .element-hidden,
html.js .js-hide {
  display: none;
}

.highlight-mark,
.new,
.update {
  color: #c00;
  background-color: transparent;
}

.inline-links,
.inline.links {
  padding: 0;
  background: #eeeeee;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
}

.inline-links__item,
.inline.links li {
  display: inline;
  list-style-type: none;
  padding: 0;
}

[dir="rtl"] .inline-links__item,
[dir="rtl"] .inline.links li,
.inline.links [dir="rtl"] li {
  display: inline-block;
  padding: 0;
}

.inline-links a, .inline.links a {
  text-transform: lowercase;
  display: inline-block;
  text-decoration: none;
  background-clip: padding-box;
  margin: 5px;
  vertical-align: middle;
  border-radius: 11px;
  background: #ddd url("/sites/all/themes/insready2016/images/button.png") repeat-x;
  border: 1px solid;
  border-color: #ddd #999999 #666666;
  cursor: pointer;
  color: #666666;
  outline: 0;
  overflow: visible;
  padding: 4px 10px 3px;
  text-shadow: #fff 0 1px 1px;
  width: auto;
}

.inline-links a:hover, .inline.links a:hover {
  color: #94bf25;
}

.inline-sibling,
.field-label-inline .field-label,
span.field-label {
  display: inline;
  margin-right: 10px;
}

[dir="rtl"] .inline-sibling,
[dir="rtl"] .field-label-inline .field-label,
.field-label-inline [dir="rtl"] .field-label,
[dir="rtl"] span.field-label {
  margin-right: 0;
  margin-left: 10px;
}

.inline-sibling__child,
.inline-sibling *, .field-label-inline .field-label *, span.field-label * {
  display: inline;
}

.inline-sibling__adjacent,
.inline-sibling + *,
.inline-sibling + * > :first-child,
.inline-sibling + * > :first-child > :first-child, .field-label-inline .field-label + *, span.field-label + *, .field-label-inline .field-label + * > :first-child, span.field-label + * > :first-child, .field-label-inline .field-label + * > :first-child > :first-child, span.field-label + * > :first-child > :first-child {
  display: inline;
}

.field-label-inline .field-label,
.field-label-inline .field-items {
  float: none;
}

.messages,
.messages--status {
  margin: 24px 0px;
  margin: 1.5rem 0rem;
  position: relative;
  padding: 10px 10px 10px 44px;
  border: 1px solid #0072b9;
}

[dir="rtl"] .messages,
[dir="rtl"] .messages--status {
  padding: 10px 44px 10px 10px;
  background-position: 99% 8px;
}

.messages__icon {
  position: absolute;
  top: 50%;
  left: 10px;
  height: 24px;
  width: 24px;
  margin-top: -12px;
  line-height: 1;
}

[dir="rtl"] .messages__icon {
  left: auto;
  right: 0;
}

.messages__icon path {
  fill: #0072b9;
}

.messages__list {
  margin: 0;
}

.messages__item {
  list-style-image: none;
}

.messages--ok-color,
.messages,
.messages--status,
.ok,
.revision-current {
  background-color: #f6fcff;
  color: #0072b9;
}

.messages--warning-color,
.messages--warning,
.messages.warning,
.warning {
  background-color: #fffce6;
  color: #000;
}

.messages--error-color,
.messages--error,
.messages.error,
.error {
  background-color: #fff0f0;
  color: #c00;
}

.messages--warning,
.messages.warning {
  border-color: #fd0;
}

.messages--warning path, .messages.warning path {
  fill: #fd0;
}

.messages--error,
.messages.error {
  border-color: #c00;
}

.messages--error path, .messages.error path {
  fill: #c00;
}

.messages--error__highlighted-text,
.messages--error .error,
.messages.error .error {
  color: #000;
}

.more-link,
.more-help-link {
  text-align: right;
}

[dir="rtl"] .more-link,
[dir="rtl"] .more-help-link {
  text-align: left;
}

.more-link__help-icon,
.more-help-link a {
  padding: 1px 0 1px 20px;
  background-image: url(../sass/components/more-link/more-link__help-icon.png);
  background-image: url(../sass/components/more-link/more-link__help-icon.svg), none;
  background-position: 0 50%;
  background-repeat: no-repeat;
}

[dir="rtl"] .more-link__help-icon,
[dir="rtl"] .more-help-link a,
.more-help-link [dir="rtl"] a {
  padding: 1px 20px 1px 0;
  background-position: 100% 50%;
}

.nav-menu__item,
.menu__item {
  list-style-image: url(../sass/components/nav-menu/leaf.svg);
  list-style-type: square;
}

.lt-ie9 .nav-menu__item,
.lt-ie9 .menu__item {
  list-style-image: url(../sass/components/nav-menu/leaf.png);
}

.nav-menu__item.is-expanded, .nav-menu__item--is-expanded,
.is-expanded.menu__item,
.menu__item.is-expanded {
  list-style-image: url(../sass/components/nav-menu/expanded.svg);
  list-style-type: circle;
}

.lt-ie9 .nav-menu__item.is-expanded, .lt-ie9 .nav-menu__item--is-expanded,
.lt-ie9 .is-expanded.menu__item,
.lt-ie9 .menu__item.is-expanded {
  list-style-image: url(../sass/components/nav-menu/expanded.png);
}

.nav-menu__item.is-collapsed, .nav-menu__item--is-collapsed,
.is-collapsed.menu__item,
.menu__item.is-collapsed {
  list-style-image: url(../sass/components/nav-menu/collapsed.svg);
  list-style-type: disc;
}

.lt-ie9 .nav-menu__item.is-collapsed, .lt-ie9 .nav-menu__item--is-collapsed,
.lt-ie9 .is-collapsed.menu__item,
.lt-ie9 .menu__item.is-collapsed {
  list-style-image: url(../sass/components/nav-menu/collapsed.png);
}

[dir="rtl"] .nav-menu__item.is-collapsed, [dir="rtl"] .nav-menu__item--is-collapsed,
[dir="rtl"] .is-collapsed.menu__item,
[dir="rtl"] .menu__item.is-collapsed {
  list-style-image: url(../sass/components/nav-menu/collapsed-rtl.svg);
}

.lt-ie9 [dir="rtl"] .nav-menu__item.is-collapsed, .lt-ie9 [dir="rtl"] .nav-menu__item--is-collapsed,
.lt-ie9 [dir="rtl"] .is-collapsed.menu__item,
.lt-ie9 [dir="rtl"] .menu__item.is-collapsed {
  list-style-image: url(../sass/components/nav-menu/collapsed-rtl.png);
}

.nav-menu__link.is-active, .nav-menu__link--is-active,
.menu a.active {
  color: #000;
}

nav {
  float: left;
}

.navbar,
.main-navigation .links,
.main-navigation .menu {
  margin: 0;
  padding: 0;
  text-align: left;
}

[dir="rtl"] .navbar,
[dir="rtl"] .main-navigation .links,
.main-navigation [dir="rtl"] .links,
[dir="rtl"]
.main-navigation .menu,
.main-navigation [dir="rtl"] .menu {
  text-align: right;
}

.navbar__item,
.navbar li, .main-navigation .links li,
.main-navigation .menu li {
  float: left;
  display: inline-block;
  padding: 0;
  list-style-type: none;
  list-style-image: none;
}

[dir="rtl"] .navbar__item, [dir="rtl"]
.navbar li, [dir="rtl"] .main-navigation .links li, .main-navigation [dir="rtl"] .links li, [dir="rtl"]
.main-navigation .menu li,
.main-navigation [dir="rtl"] .menu li {
  float: right;
  padding: 0 0 0 10px;
}

.navbar__item a,
.navbar li a, .main-navigation .links li a,
.main-navigation .menu li a {
  text-decoration: none;
  color: #fff;
  display: inline-block;
  padding: 15px;
  text-transform: lowercase;
}

.navbar__item a.active,
.navbar li a.active, .main-navigation .links li a.active,
.main-navigation .menu li a.active {
  background-image: url("/sites/all/themes/insready2016/images/bg-footer.png");
}

.navbar__item a::first-letter,
.navbar li a::first-letter, .main-navigation .links li a::first-letter,
.main-navigation .menu li a::first-letter {
  font-size: 120%;
}

.navbar__item a:hover,
.navbar li a:hover, .main-navigation .links li a:hover,
.main-navigation .menu li a:hover {
  background: rgba(255, 255, 255, 0.4);
}

@media all and (max-width: 435px) {
  .navbar li a {
    padding: 10px;
  }
}

.pager {
  clear: both;
  padding: 0;
  text-align: center;
}

@media print {
  .pager {
    display: none;
  }
}

.pager__item,
.pager__current-item,
.pager-current,
.pager-item,
.pager-first,
.pager-previous,
.pager-next,
.pager-last,
.pager-ellipsis {
  display: inline;
  padding: 0 .5em;
  list-style-type: none;
  background-image: none;
}

.pager__current-item,
.pager-current {
  font-weight: bold;
}

@media print {
  .print-none,
  .toolbar,
  .action-links,
  .links,
  .book-navigation,
  .forum-topic-navigation,
  .feed-icons {
    display: none;
  }
}

.progress-bar,
.progress {
  font-weight: bold;
}

.progress-bar__bar,
.progress .bar {
  border-radius: 3px;
  margin: 0 .2em;
  border: 1px solid #666666;
  background-color: #ddd;
}

.progress-bar__fill,
.progress .filled {
  height: 1.5em;
  width: 5px;
  background: #0072b9 url(../sass/components/progress-bar/progress-bar.gif) repeat 0 0;
}

.progress-bar__percentage,
.progress .percentage {
  float: right;
}

[dir="rtl"] .progress-bar__percentage,
[dir="rtl"] .progress .percentage,
.progress [dir="rtl"] .percentage {
  float: left;
}

.progress-bar--inline,
.ajax-progress-bar {
  width: 16em;
  display: inline-block;
}

[dir="rtl"] .progress-bar--inline,
[dir="rtl"] .ajax-progress-bar {
  float: right;
}

.progress-throbber,
.ajax-progress {
  display: inline-block;
}

[dir="rtl"] .progress-throbber,
[dir="rtl"] .ajax-progress {
  float: right;
}

.progress-throbber__widget,
.ajax-progress .throbber {
  background: url(../sass/components/progress-throbber/progress-throbber.gif) no-repeat 0 -18px transparent;
  float: left;
  height: 15px;
  margin: 2px;
  width: 15px;
}

[dir="rtl"] .progress-throbber__widget,
[dir="rtl"] .ajax-progress .throbber,
.ajax-progress [dir="rtl"] .throbber {
  float: right;
}

.progress-throbber__widget-in-tr,
tr .ajax-progress .throbber {
  margin: 0 2px;
}

.progress-throbber__message,
.ajax-progress .message {
  padding-left: 20px;
}

.resizable-textarea {
  width: 100%;
  vertical-align: bottom;
}

.resizable-textarea__grippie,
.resizable-textarea .grippie {
  background: url(../sass/components/resizable-textarea/grippie.png) no-repeat center 2px #eeeeee;
  border: 1px solid #ddd;
  border-top-width: 0;
  cursor: s-resize;
  height: 9px;
  overflow: hidden;
}

.responsive-video,
.media-youtube-video,
.media-vimeo-preview-wrapper {
  position: relative;
  padding-bottom: 56.25%;
  padding-top: 25px;
  height: 0;
}

.responsive-video__embed,
.responsive-video iframe,
.media-youtube-video iframe,
.media-vimeo-preview-wrapper iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.responsive-video--4-3 {
  padding-bottom: 75%;
}

.skip-link {
  display: block;
  padding: 2px 0 3px;
  text-align: center;
}

.skip-link:link, .skip-link:visited {
  background-color: #666666;
  color: #fff;
}

.skip-link__wrapper {
  margin: 0;
}

@media print {
  .skip-link__wrapper {
    display: none;
  }
}

.table-drag__wrapper,
body.drag {
  cursor: move;
}

.table-drag__item,
tr.drag {
  background-color: #fffadb;
}

.table-drag__item-previous,
tr.drag-previous {
  background-color: #fff7c2;
}

.table-drag__handle,
.tabledrag-handle {
  cursor: move;
  float: left;
  height: 1.7em;
  margin-left: -1em;
  overflow: hidden;
  text-decoration: none;
}

[dir="rtl"] .table-drag__handle,
[dir="rtl"] .tabledrag-handle {
  float: right;
  margin-right: -1em;
  margin-left: 0;
}

.table-drag__handle:focus, .table-drag__handle:hover,
.tabledrag-handle:focus,
.tabledrag-handle:hover {
  text-decoration: none;
}

.table-drag__handle-icon,
.tabledrag-handle .handle {
  background: url(../sass/components/table-drag/handle-icon.png) no-repeat 6px 9px;
  height: 13px;
  margin: -.4em .5em;
  padding: .42em .5em;
  width: 13px;
}

.table-drag__handle-icon--is-hover,
.tabledrag-handle-hover .handle {
  background-position: 6px -11px;
}

.table-drag__toggle-weight-wrapper,
.tabledrag-toggle-weight-wrapper {
  text-align: right;
}

[dir="rtl"] .table-drag__toggle-weight-wrapper,
[dir="rtl"] .tabledrag-toggle-weight-wrapper {
  text-align: left;
}

.table-drag__toggle-weight,
.tabledrag-toggle-weight {
  font-size: .9em;
}

.table-drag__indentation,
.indentation {
  float: left;
  height: 1.7em;
  margin: -.4em .2em -.4em -.4em;
  padding: .42em 0 .42em .6em;
  width: 20px;
}

[dir="rtl"] .table-drag__indentation,
[dir="rtl"] .indentation {
  float: right;
  margin: -.4em -.4em -.4em .2em;
  padding: .42em .6em .42em 0;
}

.table-drag__tree-child,
.table-drag__tree-child-last,
.tree-child-last,
.table-drag__tree-child-horizontal,
.tree-child-horizontal,
.tree-child {
  background: url(../sass/components/table-drag/tree-child.png) no-repeat 11px center;
}

[dir="rtl"] .table-drag__tree-child,
[dir="rtl"] .table-drag__tree-child-last,
[dir="rtl"] .tree-child-last,
[dir="rtl"] .table-drag__tree-child-horizontal,
[dir="rtl"] .tree-child-horizontal,
[dir="rtl"] .tree-child {
  background-position: -65px center;
}

.table-drag__tree-child-last,
.tree-child-last {
  background-image: url(../sass/components/table-drag/tree-child-last.png);
}

.table-drag__tree-child-horizontal,
.tree-child-horizontal {
  background-position: -11px center;
}

.tabs,
.tabs--secondary {
  margin: 18px 0px 0px;
  margin: 1.125rem 0rem 0rem;
  line-height: 30px;
  line-height: 1.875rem;
  border-bottom: 1px solid #ddd \0/ie;
  background-image: linear-gradient(to top, #ddd 1px, transparent 1px);
  padding: 0 2px;
  list-style: none;
  white-space: nowrap;
}

@media print {
  .tabs,
  .tabs--secondary {
    display: none;
  }
}

.tabs__tab {
  float: left;
  margin: 0 3px;
  border: 1px solid #ddd;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  border-bottom-color: transparent;
  border-bottom: 0 \0/ie;
  overflow: hidden;
  background: #eeeeee;
}

[dir="rtl"] .tabs__tab {
  float: right;
}

.tabs__tab.is-active {
  border-bottom-color: #fff;
}

.tabs__tab-link-common,
.tabs__tab-link,
.tabs--secondary__tab-link {
  padding: 0px 24px;
  padding: 0rem 1.5rem;
  display: block;
  text-decoration: none;
  transition: color .3s, background .3s, border .3s;
  text-shadow: #fff 0 1px 0;
}

.tabs__tab-link {
  color: #000;
  background: #eeeeee;
  letter-spacing: 1px;
}

.tabs__tab-link:focus, .tabs__tab-link:hover {
  background: #fbfbfb;
}

.tabs__tab-link.is-active, .tabs__tab-link--is-active,
.tabs__tab-link:active {
  background: #fff;
  text-shadow: none;
}

.tabs--secondary {
  margin-top: 24px;
  margin-top: 1.5rem;
  font-size: 14px;
  font-size: 0.875rem;
  background-image: none;
}

.tabs + .tabs--secondary, .tabs--secondary + .tabs--secondary {
  margin-top: 0px;
  margin-top: 0rem;
  background-image: linear-gradient(to top, #ddd 1px, transparent 1px);
}

.tabs--secondary__tab {
  margin: 9px 3px;
  margin: 0.5625rem 0.1875rem;
  float: left;
}

[dir="rtl"] .tabs--secondary__tab {
  float: right;
}

.tabs--secondary__tab-link {
  border: 1px solid #ddd;
  border-radius: 24px;
  color: #666666;
  background: white;
}

.tabs--secondary__tab-link:focus, .tabs--secondary__tab-link:hover {
  color: #333333;
  background: #eeeeee;
  border-color: #aaaaaa;
}

.tabs--secondary__tab-link.is-active, .tabs--secondary__tab-link--is-active,
.tabs--secondary__tab-link:active {
  color: white;
  text-shadow: #333333 0 1px 0;
  background: #666666;
  border-color: black;
}

.watermark {
  display: block;
  height: 0;
  overflow: visible;
  background-color: transparent;
  color: #eeeeee;
  font-size: 75px;
  line-height: 1;
  font-family: Impact, "Arial Narrow", Helvetica, sans-serif;
  font-weight: bold;
  text-transform: uppercase;
  text-align: center;
  text-shadow: 0 0 1px rgba(0, 0, 0, 0.1);
  word-wrap: break-word;
}

body {
  background: #fff url("/sites/all/themes/insready2016/images/bg-sites.png") repeat top left;
}

.front .layout-center,
.front .layout-swap,
.layout-3col__full {
  padding: 0;
  margin: 0;
  max-width: 100%;
}

.block-search {
  float: right;
}

.block-search .form-item {
  margin: 0;
}

.block-search .form-submit {
  display: none;
}

.block-search .form-text {
  color: #666666;
  display: block;
  height: 30px;
  line-height: 20px;
  background: #fff url("/sites/all/themes/insready2016/images/searchicon.png") no-repeat scroll 5px 5px;
  padding: 5px;
  border: 1px solid #3d3d3d;
  border-radius: 5px;
  padding-left: 25px;
}

.block-locale {
  float: right;
}

.block-locale ul {
  padding-left: 0;
  margin-top: 15px;
}

.block-locale li {
  margin-right: 20px;
  padding-left: 20px;
  list-style: none;
  float: left;
}

.block-locale .zh-hans {
  background: url("/sites/all/themes/insready2016/images/zh-hans.png") no-repeat 0 6px;
}

.block-locale .en {
  background: url("/sites/all/themes/insready2016/images/en1.png") no-repeat 0 6px;
}

.particles-js {
  height: 700px;
  background: url("/sites/all/themes/insready2016/images/triangle.svg") 50% 100% no-repeat, url("/sites/all/themes/insready2016/images/insready.com_hero_image_desktop.jpg") 50% 100% no-repeat, #0073ba;
}

.pane-block-10 {
  max-width: 40%;
  position: absolute;
  left: 30%;
  top: 10%;
  z-index: 100;
  color: #fff;
  text-align: center;
}

.pane-block-14 {
  background: #fff;
}

.pane-block-14 h2 {
  text-align: center;
  margin-top: 0;
  padding-top: 20px;
}

.pane-block-14 ul {
  margin: 0 auto;
  max-width: 1180px;
  text-align: center;
  padding-left: 0;
}

.pane-block-14 ul li {
  display: inline-block;
  width: 125px;
  margin-right: 20px;
  text-align: center;
}

.pane-block-14 ul .tech-name {
  clear: both;
}

.pane-our-clients-panel-pane-1 h2 {
  text-align: center;
  margin-bottom: 100px;
}

.pane-our-clients-panel-pane-1 .views-row {
  display: inline-block;
  width: 33%;
  position: relative;
  margin-bottom: 100px;
  text-align: center;
}

.pane-our-clients-panel-pane-1 .views-field-field-client-logo {
  position: absolute;
  z-index: 10;
  background: #fff;
  left: 40%;
  border-radius: 50%;
  top: -50px;
  height: 100px;
  box-shadow: 0 0 5px 1px #ddd;
}

.pane-our-clients-panel-pane-1 .views-field-field-client-logo img {
  border-radius: 50%;
}

.pane-our-clients-panel-pane-1 .views-field-field-image img {
  -webkit-filter: grayscale(1);
  -webkit-filter: grayscale(100%);
  filter: grayscale(100%);
  filter: #999999;
  transition: 1s all;
}

.pane-our-clients-panel-pane-1 .views-field-field-image img:hover {
  -webkit-filter: none;
  filter: none;
}

.pane-blocks h2 {
  text-align: center;
}

.pane-blocks .view-blocks {
  max-width: 1180px;
  margin: 0 auto;
}

.pane-blocks .view-content {
  height: 200px;
}

.pane-blocks .views-row {
  width: 100%;
  max-width: 550px;
  display: inline-block;
  float: left;
  margin-bottom: 15px;
}

.pane-blocks .views-row:first-child {
  margin-right: 50px;
}

.pane-blocks .views-field-title,
.pane-blocks .views-field-body {
  background-color: #eeeeee;
  padding: 15px;
  margin-left: 47px;
}

.pane-blocks .views-field-title {
  font-size: 20px;
  font-size: 1.25rem;
}

.pane-blocks .views-field-body {
  height: 110px;
}

.pane-blocks .views-field-body p {
  margin-top: 0;
}

.pane-blocks .views-field-picture {
  float: right;
  margin: 5px;
}

.pane-blocks .views-field-created-1 {
  border-radius: 3px;
  background-color: #fff;
  border: 1px solid #eeeeee;
  border-bottom: 1px solid #ddd;
  float: left;
  padding: 15px;
  margin: 15px;
}

.pane-blocks .views-field-created-1 .month {
  display: block;
  line-height: 22px;
  text-align: center;
  color: #0073ba;
  border-bottom: 1px solid #eeeeee;
}

.pane-blocks .more-link {
  width: 50px;
  margin: 0 auto;
}

.region-bottom-wrapper {
  background: url("/sites/all/themes/insready2016/images/bg-footer.png") repeat-x;
  height: 180px;
  border-top: 6px solid #ddd;
}

.region-bottom {
  max-width: 1180px;
  margin: 0 auto;
  color: #fff;
}

.region-bottom .block-menu {
  float: right;
  text-align: right;
  margin-top: 15px;
}

.region-bottom .block-menu h2 {
  font-size: 16px;
  font-size: 1rem;
  display: inline-block;
  margin: 0;
}

.region-bottom .block-menu ul {
  margin-top: 0;
}

.region-bottom .block-menu li {
  display: inline-block;
  margin-left: 20px;
}

.region-bottom a:link,
.region-bottom a:visited {
  color: #fff;
}

.region-bottom a:hover {
  color: #0073ba;
}

.region-bottom #block-block-6 {
  float: right;
  clear: right;
}

.region-bottom #block-block-6 p {
  margin-bottom: 0;
}

.user-picture {
  float: right;
  margin-left: 15px;
}

.menu-name-main-menu {
  background-image: url("/sites/all/themes/insready2016/images/bg-navbar.png");
  background-color: #3d3d3d;
  padding: 15px;
  margin-top: 100px;
}

.menu-name-main-menu a {
  text-decoration: none;
  color: #fff;
  display: inline-block;
  font-size: 100%;
  width: 210px;
  line-height: 30px;
  padding-left: 15px;
}

.menu-name-main-menu a.active-trail {
  color: #94bf25;
}

.menu-name-main-menu a:hover {
  color: #0073ba;
}

.page-blogs ul {
  padding-left: 0;
}

.page-blogs li {
  list-style: none;
}

.page-blogs header {
  padding-left: 0;
}

.page-works td {
  width: 32%;
  text-align: center;
  display: inline-block;
}

.page-works td span {
  display: block;
}

.page-works tr {
  display: block;
  margin-bottom: 20px;
}

@media all and (min-width: 436px) and (max-width: 1024px) {
  .particles-js {
    height: 380px;
    background: url("/sites/all/themes/insready2016/images/triangle.svg") 50% 100% no-repeat, url("/sites/all/themes/insready2016/images/insready.com_hero_image_tablet.jpg") 50% 100% no-repeat, #0073ba;
  }
  .pane-our-clients-panel-pane-1 .views-row {
    width: 49%;
  }
  .pane-block-10 {
    max-width: 100%;
    top: 1%;
    left: 0;
    padding: 20px;
  }
  .pane-blocks .views-row {
    max-width: 500px;
  }
  .pane-blocks .views-row:first-child {
    margin-right: 0;
  }
}

@media all and (max-width: 435px) {
  .particles-js {
    height: 190px;
    background: url("/sites/all/themes/insready2016/images/triangle.svg") 50% 100% no-repeat, url("/sites/all/themes/insready2016/images/insready.com_hero_image_phone.jpg") 50% 100% no-repeat, #0073ba;
  }
  .pane-our-clients-panel-pane-1 .views-row {
    width: 100%;
  }
  .pane-block-10 {
    max-width: 100%;
    top: 0;
    left: 0;
    padding: 20px;
    background: rgba(0, 0, 0, 0.2);
  }
  .pane-block-10 h1 {
    font-size: 16px;
    font-size: 1rem;
    line-height: 14px;
  }
  .region-bottom .block-menu {
    float: none;
    margin-top: 0;
    margin-right: 15px;
  }
  .region-bottom .block-menu h2 {
    display: none;
  }
  .region-header {
    position: absolute;
    right: 0;
    top: 0;
  }
  .block-locale ul {
    margin: 5px;
  }
  .block-locale ul li {
    margin-right: 5px;
  }
  .page-works td {
    width: 100%;
  }
}
