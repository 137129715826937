// Divider
//
// Can be used as an `<hr>`, an empty `<div>` or as a container.
//
// Markup: divider.hbs
//
// Style guide: components.divider

.divider,
%divider {
  @include margin(1 0);
  border: 0;
  border-top: 1px solid color(border);

  // If used as a container, add a top margin to the first child.
  > :first-child {
    @include margin-top(1);
  }
}
