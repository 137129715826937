// Dependencies.
@import 'base/headings/headings';
@import 'components/clearfix/clearfix';

// Branding header
//
// Markup: header.hbs
//
// Style guide: components.header

.header-wrapper {
  background-image: url('/sites/all/themes/insready2016/images/bg-navbar.png');
  margin: 0;
  background-color: color(accent-color);
  border-bottom: 2px;
  border-bottom-color: color(primary-color);
  border-bottom-style: solid;
}

.header,
%header {
  @extend %clearfix;

  // Wrapping link for logo.
  &__logo {
    float: left;
    margin: 7px $spacing-small 0 0;
    padding: 0;

    @include rtl() {
      float: right;
      margin: 0 0 0 10px;
    }
  }

  // Logo image.
  &__logo-image {
    vertical-align: bottom;
  }

  // Wrapper for website name and slogan.
  &__name-and-slogan {
    @include font-size(s);
    float: left;
    color: color('white');
  }

  // The name of the website.
  &__site-name {
    @include font-size(m);
    line-height: 1em;
    margin: $spacing-small 150px 0 0;

    span {
      color: color('white');
    }
  }

  // The link around the name of the website.
  &__site-link {
    &:link,
    &:visited {
      color: color(text);
      text-decoration: none;
    }

    &:hover,
    &:focus {
      text-decoration: underline;
    }
  }

  // The slogan (or tagline) of a website.
  &__site-slogan {
    margin: 0;
    font-style: italic;
  }

  // The secondary menu (login, etc.)
  &__secondary-menu {
    float: right;

    @include rtl() {
      float: left;
    }
  }

  // Wrapper for any blocks placed in the header region.
  &__region {
    position: relative;
  }
}
